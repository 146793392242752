<template>
  <div class="latestincome">
    <div class="flex title">
      <div class="newIncome">最新收入</div>
      <!-- <div class="historyInfo" @click="seeDetail">查看历史收入详情></div> -->
    </div>
    <div v-if="nodata" class="nodata-wrap">
      <img :src="nodataImg" class="nodata-img">
      <p>您还没有首月收益，下月即可查看账单~</p>
      <div class="back-btn" @click="backClick">返回首页</div>
    </div>
    <div v-else class="content">
      <img :src="latestdetail" class="latestdetail-img">
      <div class="detail-content">
        <div class="flex top">
          <div>数据明细</div>
          <div>{{pageInfo.dateMonth}}</div>
        </div>
        <div class="num">{{pageInfo.monthServiceFee}}</div>
        <div class="mid">
          <div class="mid-item flex"><span>数据日期</span><span>{{pageInfo.dataDate}}</span></div>
          <div class="mid-item flex"><span>月日均（元）</span><span>{{pageInfo.monthDayAvgAmt}}</span></div>
          <div class="mid-item flex" v-if="(this.province == '山东省' || this.province == '陕西省')&&pageInfo.stockYearMonthDayAvg"><span>存量业绩月日均（元）</span><span>{{pageInfo.stockYearMonthDayAvg}}</span></div>
          <div class="mid-item flex" v-if="(this.province == '山东省' || this.province == '陕西省')&&pageInfo.oneYearMonthDayAvg"><span>1年（不含）以下新增月日均（元）</span><span>{{pageInfo.oneYearMonthDayAvg}}</span></div>
          <div class="mid-item flex" v-if="(this.province == '山东省' || this.province == '陕西省')&&pageInfo.twoYearMonthDayAvg"><span>1及2年定期新增月日均（元）</span><span>{{pageInfo.twoYearMonthDayAvg}}</span></div>
          <div class="mid-item flex" v-if="(this.province == '山东省' || this.province == '陕西省')&&pageInfo.threeYearMonthDayAvg"><span>3年及以上新增月日均（元）</span><span>{{pageInfo.threeYearMonthDayAvg}}</span></div>
          <div class="mid-item flex"><span>服务费率（月日均）</span><span>{{pageInfo.monthDayRateStr}}</span></div>
          <div class="mid-item flex"><span>基础服务费（元）</span><span class="bold blackcolor">{{pageInfo.basicServiceFee}}</span></div>
          <div class="mid-item flex"><span>考核服务费（元）</span><span class="bold blackcolor">{{pageInfo.settleFee}}</span></div>
          <div class="mid-item flex"><span>激励（元）</span><span class="bold blackcolor">{{pageInfo.incentiveAmt}}</span></div>
          <div class="remark-box flex" v-if="pageInfo.remark">
            <span style="width: 240px">备注</span>
            <span style="text-indent:15px">{{pageInfo.remark}}</span>
          </div>
          <div class="mid-item flex">
            <span>总计（元）</span>
            <span class="bold blackcolor">{{pageInfo.monthServiceFee}}</span>
          </div>
        </div>
      </div>
      <div class="bank-wrap">
        <van-field v-model="bankInfo.bankCard" label="收款账号" input-align="right"></van-field>
        <van-field v-model="bankInfo.issueBank" label="收款银行" input-align="right"></van-field>
        <van-field v-model="bankInfo.way" label="结算方式" input-align="right"></van-field>
      </div>
      <div class="tips" v-if="this.incomeMethod == 'INVOICE'">提示：以上服务费均为包含税费金额。常规情况下每月28号发放上上月服务费（遇特殊情况或节假日顺延），您最晚需要在每月22号之前确认上上月收益，若您未在规定时间前确认，相关已确认收益将顺延发放。</div>
      <div class="tips" v-if="this.incomeMethod == 'REMUNERATION'">提示：以上服务费均为包含税费金额。常规情况下每月25号发放上上月服务费（遇特殊情况或节假日顺延），您最晚需要在每月22号之前确认上上月收益，若您未在规定时间前确认，相关已确认收益将顺延发放。</div>
      <div class="tips" v-if="this.incomeMethod != 'INVOICE' && this.incomeMethod != 'REMUNERATION'&&this.province != '河南省'">提示：以上服务费包含站长佣金税费、代缴平台服务费成本、代缴平台发票成本等相关费用。常规情况下每月25号发放上上月服务费（若遇特殊情况或节假日则相应顺延），故您最晚需要在每月22号之前确认上上月收益，若您未在规定时间前确认，相关已确认收益将顺延发放。</div>
      <div class="tips" v-if="this.incomeMethod != 'INVOICE' && this.incomeMethod != 'REMUNERATION'&&this.province == '河南省'">提示：常规情况下每月25号发放上月服务费（若遇特殊情况或节假日则相应顺延），故您最晚需要在每月22号之前确认上月收益，若您未在规定时间前确认，相关已确认收益将顺延发放。</div>
    </div>


    <div v-if="!nodata" class="footer">
      <div class="sign-wrap" v-if="this.incomeMethod != 'INVOICE' && this.incomeMethod != 'REMUNERATION'&& bankInfo.status != 'COMPLETE'  && pageInfo.status == 0" >
        签署<span @click="renliBaoClick">《共享经济综合服务协议》</span></div>
      <van-button slot="button" class="sign-button" color="#3F7C53" @click="confirmClick" :disabled="pageInfo.status != 0">
        {{pageInfo.status == 0 ? '确认' : '已确认'}}</van-button>
    </div>

    <van-action-sheet v-model="dialogShow" :title="this.incomeMethod === 'INVOICE' ?'开票':this.incomeMethod === 'REMUNERATION' ? '劳务报酬':'提现'">
      <van-field v-model="bankInfo.bankCard" label="收款账号" input-align="right"> </van-field>
      <van-field v-model="bankInfo.way" label="结算方式" input-align="right"> </van-field>
      <van-field v-model="pageInfo.monthServiceFee" label="月度收入（元）" input-align="right" label-width="2rem"> </van-field>
      <div class="box-button flex">
        <van-button type="default" plain @click="dialogShow = false">取消</van-button>
        <van-button type="default" plain @click="confirm" color="#3F7C53">确认</van-button>
      </div>
    </van-action-sheet>


    <van-dialog v-model="agreementDialog" title="提示" confirmButtonText="知道了" confirmButtonColor="#3F7C53" cancelButtonColor="#B5BBC9">
      <div style="margin:0.2rem 0 0.48rem 0.5rem;color: #333333; font-size:0.3rem">请签署《共享经济综合服务协议》</div>
    </van-dialog>


  </div>
</template>

<script>
import {getMasterIncome, confirmIncome, getMasterHireType, getLangDa, getRealStatus,getHomeInfo} from '@/utils/api'
import common from '../../../utils/common'
import { Toast} from "vant";
export default {
  components:{
    [Toast.name]: Toast
  },
  data() {
    return{
      latestdetail: require('@/assets/img/stationagent/latestdetail.png'),
      pageInfo: {
        status: 0
      },
      nodata: true,
      nodataImg: require('@/assets/img/stationagent/nodata.png'),
      tempToken: '',
      province: '',
      dialogShow: false,
      incomeMethod:'',
      age:0,
      bankInfo:{
        bakkName: '',
        bankNo: '',
        status: '',
        way: ''
      },
      agreementDialog: false,
      signStr:''
    }
  },
  created() {
    getHomeInfo({uniqueNo:common.getItem('uniqueNo')}).then(res=>{
      if(res.code===200&&res.data.businessCode===200){
        this.signStr = res.data
      }
    })
  },
  mounted(){
    this.tempToken = this.$route.query.jumpFrom !=undefined ? this.$route.query.token : common.getItem('wtToken')
    this.togetMasterIncome()
    this.togetMasterHireType()
  },
  methods:{
    renliBaoClick() {
      getLangDa({
        uniqueNo: this.bankInfo.uniqueNo,
        incomeMethod:this.incomeMethod
      }).then(res=>{
        if(res.code == 200){
          if(res.data && res.data.businessCode == 200) {
            if (this.incomeMethod === 'FLEXIBLEMICRO'){
              this.$router.push({path:'/cash/imcroframe',
                query:{
                  URL: res.data.url
                }})
            }else {
              window.location.href = res.data.url
            }
            //window.location.href = res.data.url
          }
        }
      })
    },
    togetMasterHireType() {
      getMasterHireType({token: this.tempToken, id: this.$route.query.id}).then(res=>{
        if(res.code === 200 ){
          this.bankInfo = res.data;
          this.incomeMethod=res.data.incomeMethod;
          this.age=res.data.age;
          if(res.data && res.data.businessCode == '30080'){ // 去签约
            this.bankInfo.way = '提现';
          }
          if(res.data && res.data.businessCode == '200'){
            this.bankInfo.way = res.data.incomeMethod === 'INVOICE' ? '开票' : res.data.incomeMethod === 'REMUNERATION' ? '劳务报酬' : '提现';
          }
        }
      })
    },
    confirmClick() {
      if(this.province!="山东省"&&this.age>65&&this.bankInfo.way == '提现'){
        Toast('您已超过65岁,不符合灵活用工方式,请联系运营处理')
        return;
      }
      if(this.signStr.stationStep==='END' && this.signStr.stepStr==='您有协议待签署'){
        Toast('请先至首页完成协议签署!')
        return;
      }
      if( this.incomeMethod == 'INVOICE' ||  this.incomeMethod == 'REMUNERATION') {
        this.dialogShow = true;
        return;
      }
      if(this.bankInfo.status != 'COMPLETE'){
        getRealStatus({
          uniqueNo: this.bankInfo.uniqueNo,
          incomeMethod:this.incomeMethod
        }).then(res=>{
          if(res.code == 200){
            if(res.data && (res.data.businessCode == 0 || res.data.businessCode == 200 ) ){
              if(res.data.status != 'COMPLETE'){
                this.agreementDialog = true
              }else{
                this.dialogShow = true
              }
            }else{
              this.agreementDialog = true
            }
          }
        })
        return
      }
      if(this.bankInfo.status == 'COMPLETE'){
        this.dialogShow = true
        return
      }
    },
    togetMasterIncome(){
      getMasterIncome({
        token: this.tempToken,
        id: this.$route.query.id
      }).then(res=>{
        if(res.data.data){
          this.nodata = false
          this.pageInfo = res.data.data
          this.province = res.data.province
        }else{
          this.nodata = true
        }
      })
    },
    confirm(){
      confirmIncome({
        id:this.pageInfo.id,
        status: 1,
        settleMethod: this.incomeMethod,
        //settleMethod: this.bankInfo.status === 'BLACK' ? 'INVOICE': this.bankInfo.status ==='REMUNERATION'? 'REMUNERATION' : this.bankInfo.status ==='FLEXIBLE'?'FLEXIBLE':'FLEXIBLEQGB',  // INVOICE 开票， FLXCIBLE 灵活用工
        bankCardNo: this.bankInfo.bankCard,
        issueBank: this.bankInfo.issueBank
      }).then( res=>{
        this.dialogShow = false
        this.togetMasterIncome()
        this.togetMasterHireType()
      })
    },
    backClick(){
      this.$router.push({name:'homePage'})
    },
    seeDetail(){
      this.$router.push({name: 'historyincome'})
    }
  }
}
</script>

<style lang="less">
.latestincome{
  .van-dialog{
    border-radius: 16px !important;
    width: 5.6rem;
    height: 3rem;
  }
  .van-dialog__header{
    color: #333333;
    font-size: 0.36rem;
    font-weight: bold;
    padding-top: 0.4rem;
  }
  .van-button__text{
    font-size: 0.36rem;
  }
  .van-action-sheet__header{
    margin: 0.3rem;
    text-align: left;
    color: #272829;
    font-size: 0.36rem;
    font-weight: bold;
  }
}
</style>

<style lang="less" scoped>
  .remark-box{
    justify-content: space-between;
    align-items: center;
    color: #9B9B9B;
    margin: 0.25rem 0.6rem;
  }
.latestincome{
  background: #F7F8F9;
  height: 100%;
  width:100%;
  min-height: 100%;
  .flex{
    display: flex;
  }
  .latestdetail-img{
    width: 7.14rem;
    height: 480px;
    margin: 0 50%;
    transform: translateX(-50%);
  }
  .van-overlay{
    z-index: 1005;
  }
  .box-button{
    width: 100%;
    font-size:0.36rem;
    justify-content: center;
    .van-button{
      width: 3.39rem;
      height: 0.98rem;
      border:0.01rem solid #E5E5E5;
      color: #333;
      margin: 0.24rem;
      &:nth-child(2){
       background: #3F7C53;
       color: #fff !important;
       margin-left: 0;
      }
    }
  }
  .title{
    box-sizing: border-box;
    padding: 0.24rem 0.3rem 0.2rem;
    justify-content: space-between;
    width: 100%;
    .newIncome{
      font-size: 0.36rem;
      color: #1E1E1E;
    }
    .historyInfo{
      font-size: 0.28rem;
      color: #3F7C53;
    }
  }
  .nodata-wrap{
    text-align: center;
    .nodata-img{
      width:4.2rem;
      height: 3.14rem;
      margin-top: 1.8rem;
    }
    p{
      font-size: 0.3rem;
      color: #616161;
    }
    .back-btn{
      width: 1.96rem;
      height: 0.78rem;
      line-height: 0.78rem;
      border: 0.02rem solid #3F7C53;
      border-radius: 39px;
      color: #3F7C53;
      margin:0.8rem auto;
      font-size: 0.28rem;
    }
  }
  .content{
    box-sizing: border-box;
    padding: 0 0.18rem 2.32rem;
    position: relative;
    background: #F7F8F9;
    .detail-content{
      width: 7.14rem;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 0;
      .top{
        justify-content: space-between;
        padding: 0.38rem 0.6rem 0;
        div{
          &:nth-child(1){
            color: #272829;
            font-size: 0.28rem;
          }
          &:nth-child(2){
            color: #9B9B9B;
            font-size: 0.28rem;
          }
        }
      }
      .num{
        color: #272829;
        font-size: 1rem;
        text-align: center;
        margin: 0.4rem;
      }
      .mid{
        margin-top: 0.5rem;
      }
      .bold{
        font-weight: bold;
      }
      .blackcolor{
        color: #272829;
      }
      .mid-item{
        justify-content: space-between;
        align-items: center;
        color: #9B9B9B;
        margin: 0.25rem 0.6rem;
        span{
          &:nth-child(1){
            font-size: 0.26rem;
          }
          &:nth-child(2){
            font-size: 0.28rem;
          }
        }
        &:nth-last-child(1){
          margin-top: 0.5rem;
          span:nth-child(2){
            font-size: 0.36rem;
          }
        }
      }
    }
    .bank-wrap{
      margin-top:0.32rem;
    }
    .tips{
      font-size: 0.24rem;
      color: #999;
      margin: 0.26rem 0.3rem 0;
    }
  }
  .sign-wrap{
    background: #F7F8F9;
    padding: 0.2rem 0.24rem 0.2rem;
    font-size: 0.3rem;
    span{
      color: #3F7C53;
    }
  }
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    // margin-bottom: 0.2rem;
    z-index: 1001;
    .sign-button{
      border-radius: 4px;
      font-size: 18px;
      background-color: #3F7C53;
      color: #FFFFFF;
      order: solid #FFFFFF 0;
      width: 7.1rem;
      margin:0.2rem;
    }
  }
}
</style>
